import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./components/home";
import Category from "./components/category";
import ScrollRestore from "./components/scrollRestore";
import Contact from "./components/contact";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.css";

ReactDOM.render(
  <Router>
    <Container fluid className="page">
      <div className="pt-3">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/products/:categoryKey" component={Category} />
        </Switch>
      </div>
    </Container>
    <Header />
    <Footer />
    <ScrollRestore />
    <div className="backdrop" />
  </Router>,
  document.getElementById("root")
);
