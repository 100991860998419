import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export default () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);

  const sendForm = (e) => {
    const data = new FormData(e.target);
    const reset = e.target.reset.bind(e.target);

    e.preventDefault();
    setSubmitting(true);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", process.env.REACT_APP_FORM_URL);
    xhr.setRequestHeader("Accept", "application/json");

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        setTimeout(() => {
          reset();
          setSubmitCount((submitCount) => submitCount + 1);
          setSubmitting(false);
        }, 500);
      }
    };

    xhr.send(data);
  };

  return (
    <>
      <h3 className="pb-2">Contact</h3>
      <p>Fill out the form below to see how we can help with your business.</p>
      {submitCount > 0 ? (
        <p>Thanks for your inquiry! A representative will be touch shortly.</p>
      ) : null}
      <Row>
        <Col sm={12} md={7} className="pb-3">
          <Form onSubmit={sendForm}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter name"
                required
              />
            </Form.Group>
            <Form.Group controlId="company">
              <Form.Label>Company</Form.Label>
              <Form.Control
                type="text"
                name="company"
                placeholder="Enter company"
                required
              />
            </Form.Group>
            <Form.Group controlId="phoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                placeholder="Enter phone number"
                required
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email address"
                required
              />
            </Form.Group>
            <Form.Group controlId="message">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                rows="3"
                placeholder="Enter message"
                required
              />
            </Form.Group>
            <Button type="submit" disabled={submitting}>
              {submitting ? "Sending.." : "Submit"}
            </Button>
          </Form>
        </Col>
        <Col sm={12} md={5} className="pb-3">
          <img src="/images/shaking-hands.jpg" style={{ maxWidth: "100%" }} />
        </Col>
      </Row>
      <p>Need to reach us sooner? Use a phone number or email below.</p>
      <Row>
        <Col sm={12}>
          <h6>Ronnie</h6>
          <p>
            ✉️ <a href="mailto:ronnie@sbsllc.net">ronnie@sbsllc.net</a>
            <br />
            📞 <a href="tel:7323377262">732-337-7262</a>
          </p>
        </Col>
      </Row>
    </>
  );
};
