import React, { useState, useEffect, useRef } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link, withRouter } from "react-router-dom";
import { catalog } from "./catalog";

export default withRouter(({ location }) => {
  const wrapperRef = useRef(null);
  const [expandedMenu, setExpandedMenu] = useState(false);

  useEffect(() => {
    setExpandedMenu(false);
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setExpandedMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <>
      <Navbar
        bg="light"
        expand="lg"
        expanded={expandedMenu}
        className="header"
        ref={wrapperRef}
      >
        <Navbar.Brand />
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpandedMenu(!expandedMenu)}
        />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Link className="nav-link" to="/">
              About
            </Link>
            <Link className="nav-link" to="/contact">
              Contact
            </Link>
            {Object.keys(catalog).map((categoryKey) => (
              <Link
                key={categoryKey}
                className="nav-link"
                to={`/products/${categoryKey}`}
              >
                {catalog[categoryKey].title ||
                  categoryKey
                    .split("-")
                    .map(
                      (word) =>
                        `${word.substr(0, 1).toUpperCase()}${word.substr(1)}`
                    )
                    .join(" ")}
              </Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Link className="logo" to="/">
        <img src="/images/logo.png" />
      </Link>
    </>
  );
});
