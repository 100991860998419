import React, { cloneElement, Fragment } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { catalog } from "./catalog";
import { Carousel } from "react-responsive-carousel";

export default ({
  match: {
    params: { categoryKey },
  },
}) => {
  const category = catalog[categoryKey];

  const title =
    category.title ||
    categoryKey
      .split("-")
      .map((word) => `${word.substr(0, 1).toUpperCase()}${word.substr(1)}`)
      .join(" ");

  return (
    <Fragment key={categoryKey}>
      <h3 className="pb-2">{title}</h3>
      <div className="pb-2">
        {category.top.map((item, index) => (
          <Fragment key={index}>
            {typeof item === "string" ? <p>{item}</p> : cloneElement(item)}
          </Fragment>
        ))}
      </div>
      <Row>
        <Col sm={12} md={4}>
          {category.left.map((item, index) => (
            <Fragment key={index}>
              {typeof item === "string" ? <p>{item}</p> : cloneElement(item)}
            </Fragment>
          ))}
        </Col>
        <Col sm={12} md={8}>
          <Carousel
            dynamicHeight={true}
            autoPlay={true}
            infiniteLoop={true}
            interval={1500}
          >
            {category.carousel.map(({ image, text }, index) => (
              <Fragment key={index}>
                {!image ? null : <img className="img-content" src={image} />}
                {!text ? null : <p className="legend">{text}</p>}
              </Fragment>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Fragment>
  );
};
