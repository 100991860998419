import React from "react";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import { catalog } from "./catalog";

export default () => {
  const itemLength = Math.round(Object.keys(catalog).length / 2);

  return (
    <>
      <h3 className="pb-2">Sustainable Building Solutions</h3>
      <h5 className="text-muted pb-2">
        The Tri-State's Leader in Commercial and Residential Building Supplies
      </h5>
      <p>
        Sustainable Building Solutions, LLC is based out of Monmouth County, New
        Jersey, and specializes in commercial and residential building supplies.
        We proudly serve the Tri-State area in a number of areas, including
        lighting, plumbing, tool rentals, janitorial products and more.
      </p>
      <p className="pb-2">
        We specialize in green and sustainable solutions to keep your space safe
        and efficient while minimizing environmental impact. We pride ourselves
        in stocking hard-to-find items. We aim to be your #1 source, so{" "}
        <Link to="/contact">contact us</Link> today to see how we can help!
      </p>
      <div className="d-md-none pb-3">
        <ListGroup>
          {Object.keys(catalog).map((categoryKey) => (
            <ListGroup.Item key={categoryKey}>
              <Link to={`/products/${categoryKey}`} className="home-link-block">
                {catalog[categoryKey].emoji}{" "}
                {catalog[categoryKey].title ||
                  categoryKey
                    .split("-")
                    .map(
                      (word) =>
                        `${word.substr(0, 1).toUpperCase()}${word.substr(1)}`
                    )
                    .join(" ")}
              </Link>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
      <div className="d-none d-md-block pb-3">
        <Row>
          {[0, itemLength].map((start) => (
            <Col key={start} sm={12} md={5}>
              <ListGroup>
                {Object.keys(catalog)
                  .slice(start, start + itemLength)
                  .map((categoryKey) => (
                    <ListGroup.Item key={categoryKey}>
                      <Link
                        to={`/products/${categoryKey}`}
                        className="home-link-block"
                      >
                        {catalog[categoryKey].emoji}{" "}
                        {catalog[categoryKey].title ||
                          categoryKey
                            .split("-")
                            .map(
                              (word) =>
                                `${word
                                  .substr(0, 1)
                                  .toUpperCase()}${word.substr(1)}`
                            )
                            .join(" ")}
                      </Link>
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};
