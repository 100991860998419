import React from "react";

export const catalog = {
  "covid-19": {
    title: "COVID-19",
    emoji: "😷",
    top: [
      "Re-opening your business? Let us assist in the safety of you and your tenants / patrons. From numerous IN STOCK disinfecting / sanitizing products, to all PPE and CDC recommended safety and social distancing signage, we'll help keep your business safe and your tenants / patrons satisfied.",
    ],
    left: [
      "Ask us about:",
      <ul>
        <li>All PPE</li>
        <li>KN95 masks, medical masks, and spit guards</li>
        <li>Forehead IR temperature guns</li>
        <li>
          Proprietary Hand Sanitizer, gallon refills with pump tops, 16oz, 8oz
          bottles
        </li>
        <li>
          Touchless Hand Sanitizer Dispensers, wall mounted or with a stand
        </li>
        <li>80ct Jumbo Tubes disinfectant wipes</li>
        <li>
          Virucide liquid, all surface disinfectants, ready to use and
          concentrated formulas
        </li>
        <li>Handheld and backpack Victory Electrostatic Sprayers </li>
        <li>
          Reopening signage for social distancing, mask requirements, elevator
          and bathroom capacity requirements
        </li>
      </ul>,
    ],
    carousel: [
      {
        image: "/images/covid1.jpg",
      },
      {
        image: "/images/covid2.jpg",
      },
      {
        image: "/images/covid3.jpg",
      },
      {
        image: "/images/covid4.jpg",
      },
      {
        image: "/images/covid5.jpg",
      },
      {
        image: "/images/covid6.jpg",
      },
      {
        image: "/images/covid7.jpg",
      },
    ],
  },
  "building-supplies": {
    emoji: "🏢",
    top: [
      "At Sustainable Building Solutions, we take pride in our experience and knowledge of all materials required throughout the entire envelope of a commercial and residential building. From ceiling tiles to floor runners, temporary heating and A/C, safety equipment and local law requirements, we know it all!",
      "We also deal in seasonal maintenance products like calcium chloride and all hand and power tools. We stay ahead of the curve to provide the right item the first time, keep purchasing easy, and delivery turnaround FAST while not breaking the bank.",
    ],
    left: [
      "Ask us about:",
      <ul>
        <li>USG and Armstrong Ceiling Tiles</li>
        <li>Benjamin Moore and Sherwin Williams Paint</li>
        <li>Basic and custom cut floor runners</li>
        <li>NYC Local Law 26 Glow Tape</li>
        <li>All sizes and merv type air filters</li>
        <li>All building and safety custom signage</li>
        <li>Portable Heat / AC</li>
        <li>Peladow Calcium Chloride</li>
        <li>Power tool rentals</li>
        <li>Replacement parts or products, try us!</li>
      </ul>,
    ],
    carousel: [
      {
        image: "/images/building1.jpg",
      },
      {
        image: "/images/building2.jpg",
      },
      {
        image: "/images/building3.jpg",
      },
      {
        image: "/images/building4.jpg",
      },
      {
        image: "/images/building5.jpg",
      },
      {
        image: "/images/building6.jpg",
      },
      {
        image: "/images/building7.jpg",
      },
      {
        image: "/images/building8.jpg",
      },
      {
        image: "/images/building9.jpg",
      },
      {
        image: "/images/building10.jpg",
      },
    ],
  },
  lighting: {
    emoji: "💡",
    top: [
      "We are a distributor for all major brands of LED Lighting. Whether you're looking to replace an old style bulb with a more energy efficient version, or improve a room's overall light output, we have vast knowledge of all existing and new to be released LED lamp technologies. We can help replace that one defective fixture from your lighting upgrade a few years back, or can recommend a better more cost effective version.",
      "Want to upgrade your existing high usage fluorescent or HID lighting application? We have a vast knowledge of and access to all LED lamps for retrofit / replacement purposes.",
      "We are also certified and trained in Con Edison's Instant Lighting Incentive Program, and have experience in additional lighting rebates / incentives with other utility companies throughout the tri-state area. We discount your LED lamp purchase up front and recoup incentives from Con Ed at no cost to the end user.",
    ],
    left: [
      "Ask us about:",
      <ul>
        <li>CFL to LED retrofit lamps and kits</li>
        <li>T12-T5 LED Tubes</li>
        <li>HID retrofit lamps and fixtures</li>
        <li>Interior Office Lighting</li>
        <li>Mechanical / Stairwell Lighting</li>
        <li>Elevator Lighting Retrofit</li>
        <li>Exterior Lighting</li>
        <li>Specialty Lighting</li>
      </ul>,
    ],
    carousel: [
      {
        image: "/images/lighting1.jpg",
      },
      {
        image: "/images/lighting2.jpg",
      },
      {
        image: "/images/lighting3.jpg",
      },
      {
        image: "/images/lighting4.jpg",
      },
      {
        image: "/images/lighting5.jpg",
      },
    ],
  },
  residential: {
    emoji: "🏡",
    top: [
      "Re-opening your business? The safety of you and your customers are our top concern.",
    ],
    left: [
      "Ask us about:",
      <ul>
        <li>Lighting</li>
        <li>CO2 / Smoke Detectors</li>
      </ul>,
    ],
    carousel: [
      {
        image: "/images/residential1.jpg",
      },
      {
        image: "/images/residential2.jpg",
      },
      {
        image: "/images/residential3.jpg",
      },
      {
        image: "/images/residential4.jpg",
      },
      {
        image: "/images/residential5.jpg",
      },
    ],
  },
  plumbing: {
    emoji: "🔧",
    top: [
      "Full service industrial plumbing supplies delivered faster than the competition. We offer plumbing fixtures from Sloan, Toto, American Standard, Proflo, Delta and more.",
    ],
    left: [
      "Ask us about:",
      <ul>
        <li>
          All touchless bathroom parts like solanoids, modules, flushometer
          rebuilds, and batteries
        </li>
        <li>Milwaukee hand and power tools for peak drain maintenance</li>
        <li>Bathroom partitions and hard to find parts</li>
        <li>
          Everyday plumbing supplies like teflon tape, spid wrenches, wax
          gaskets, and flappers
        </li>
      </ul>,
    ],
    carousel: [
      {
        image: "/images/plumbing1.jpg",
      },
      {
        image: "/images/plumbing2.jpg",
      },
      {
        image: "/images/plumbing3.jpg",
      },
      {
        image: "/images/plumbing4.jpg",
      },
      {
        image: "/images/plumbing5.jpg",
      },
      {
        image: "/images/plumbing6.png",
      },
    ],
  },
  janitorial: {
    emoji: "🧻",
    top: [
      "All customers prefer different types of cleaning products based on their needs. We pride ourselves in being able to obtain and stock all types of cleaning supplies on the market, with an emphasis on green, environmentally friendly chemicals and reusable methods.",
    ],
    left: [
      "Ask us about:",
      <ul>
        <li>Chemicals</li>
        <li>Paper Goods</li>
        <li>Cleaning Supplies</li>
      </ul>,
    ],
    carousel: [
      {
        image: "/images/janitorial1.jpg",
      },
      {
        image: "/images/janitorial2.jpg",
      },
    ],
  },
  services: {
    emoji: "👷",
    top: [
      "In addition to the everyday supplies required to run today's commercial and residential buildings, we're ready for the uncommon and unexpected. Allow us to help provide assistance with the following services throughout the tri-state area:",
    ],
    left: [
      <ul>
        <li>
          Carpet blowers, Move and Cool spot coolers, portable heaters, and
          Hilti construction equipment available for rental
        </li>
        <li>Pickup and match existing paint chips</li>
        <li>LED upgrade walk throughs for ROI analysis</li>
        <li>Yearly Local Law 26 repairs and inspections</li>
      </ul>,
    ],
    carousel: [
      {
        image: "/images/services1.jpg",
      },
      {
        image: "/images/services2.jpg",
      },
      {
        image: "/images/services3.png",
      },
      {
        image: "/images/services4.jpg",
      },
      {
        image: "/images/services5.jpg",
      },
    ],
  },
};
